import { MenuItem } from 'primeng/api';

export const faqMenu: MenuItem = {
  label: 'faq.faq',
  icon: 'pi pi-question-circle',
  items: [
    {
      label: 'faq.faq',
      routerLink: ['/faq/faqs']
    },
    {
      label: 'faq.what-is-solidmemory',
      routerLink: ['/faq/what-is-solidmemory']
    },
    {
      label: 'faq.how-solidmemory-works',
      routerLink: ['/faq/how-solidmemory-works']
    },
    {
      label: 'faq.why-making-solidmemory',
      routerLink: ['/faq/why-making-solidmemory']
    },
    {
      label: 'faq.why-do-humans-forget',
      routerLink: ['/faq/why-do-humans-forget']
    },
    {
      label: 'faq.how-to-make-the-best-use-of-the-platform',
      routerLink: ['/faq/how-to-make-the-best-use-of-the-platform']
    },
    {
      label: 'faq.how-to-improve-learning-effectiveness',
      routerLink: ['/faq/how-to-improve-learning-effectiveness']
    },
    {
      label: 'faq.where-is-the-bottleneck',
      routerLink: ['/faq/where-is-the-bottleneck']
    },
    {
      label: 'faq.implication-of-knowing-oxford3000-only',
      routerLink: ['/faq/implication-of-knowing-oxford3000-only']
    },

    {
      label: 'faq.money-back-guarantee',
      routerLink: ['/faq/money-back-guarantee']
    },
    {
      label: 'faq.why-phonics-is-not-enough',
      routerLink: ['/faq/why-phonics-is-not-enough']
    },
    {
      label:
        'faq.can-students-guess-the-meaning-of-an-unfamiliar-word-correctly',
      routerLink: [
        '/faq/can-students-guess-the-meaning-of-an-unfamiliar-word-correctly'
      ]
    },
    {
      label: 'faq.why-is-learning-chinese-difficult',
      routerLink: ['/faq/why-is-learning-chinese-difficult']
    },
    {
      label: 'faq.work-hard-or-work-smart',
      routerLink: ['/faq/work-hard-or-work-smart']
    },

    {
      label: 'faq.no-voice-output',
      routerLink: ['/faq/no-voice-output']
    },
    {
      label: 'faq.how-to-clear-browser-cache',
      routerLink: ['/faq/how-to-clear-browser-cache']
    },
    {
      label: 'faq.troubleshooting-guide',
      routerLink: ['/faq/troubleshooting-guide']
    }
  ]
};
