import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DelayPreloadingStrategy } from './core/delay-preloading-strategy';
import { LoggedInGuard } from './core/guards/logged-in-guard';
import { SysAdminGuard } from './core/guards/sysadmin-guard';
import { PrincipalForumRegistrationCompletedComponent } from './principal-forum/principal-forum-registration-completed.component';
import { PrincipalForumRegistrationComponent } from './principal-forum/principal-forum-registration.component';
import { AppMainComponent } from './shell/app.main.component';
import { SigninComponent } from './signin';
import { SignoutComponent } from './signout';

const routes: Routes = [
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'signout',
    component: SignoutComponent
  },
  {
    path: 'principalForumRegistration',
    component: PrincipalForumRegistrationComponent
  },
  {
    path: 'principalForumRegistrationCompleted',
    component: PrincipalForumRegistrationCompletedComponent
  },
  {
    path: 'vocabulary',
    loadChildren: () =>
      import('./vocab/vocab.module').then((m) => m.VocabModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule)
  },
  {
    path: 'school-faq',
    loadChildren: () =>
      import('./school-faq/school-faq.module').then((m) => m.SchoolFaqModule)
  },
  {
    path: 'problem-set',
    loadChildren: () =>
      import('./problem-set/problem-set.module').then(
        (m) => m.ProblemSetModule
      ),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'signUp',
    loadChildren: () =>
      import('./sign-up/sign-up.module').then((m) => m.SignUpModule)
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./support/support.module').then((m) => m.SupportModule)
  },
  {
    path: 'agreement',
    loadChildren: () =>
      import('./agreement/agreement.module').then((m) => m.AgreementModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then((m) => m.TestModule)
  },
  {
    path: 'research',
    loadChildren: () =>
      import('./research/research.module').then((m) => m.ResearchModule)
  },
  {
    path: 'freeTrial',
    loadChildren: () =>
      import('./free-trial/free-trial.module').then((m) => m.FreeTrialModule)
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule)
  },
  {
    path: 'vision',
    loadChildren: () =>
      import('./vision/vision.module').then((m) => m.VisionModule)
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'personalBank',
    loadChildren: () =>
      import('./personal-bank/personal-bank.module').then(
        (m) => m.PersonalBankModule
      ),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'system',
    loadChildren: () =>
      import('./system/system.module').then((m) => m.SystemModule),
    canActivate: [LoggedInGuard, SysAdminGuard]
  },
  {
    path: 'school',
    loadChildren: () =>
      import('./school/school.module').then((m) => m.SchoolModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'pay-dollar',
    loadChildren: () =>
      import('./pay-dollar/pay-dollar.module').then((m) => m.PayDollarModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'pay-pal',
    loadChildren: () =>
      import('./pay-pal/pay-pal.module').then((m) => m.PayPalModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'alipay',
    loadChildren: () =>
      import('./alipay/alipay.module').then((m) => m.AlipayModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'purchase',
    loadChildren: () =>
      import('./purchase/purchase.module').then((m) => m.PurchaseModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'erp',
    loadChildren: () => import('./erp/erp.module').then((m) => m.ErpModule)
  },
  {
    path: 'image',
    loadChildren: () =>
      import('./image/image.module').then((m) => m.ImageModule)
  },
  {
    path: 'revision',
    loadChildren: () =>
    import('./revision/revision.module').then(m => m.RevisionModule)
  },
  {
    path: 'word-frequency',
    loadChildren: () =>
      import('./word-frequency/word-frequency.module').then(
        (m) => m.WordFrequencyModule
      )
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          children: routes
        }
      ],
      {
        scrollPositionRestoration: 'enabled',
        useHash: false,
        preloadingStrategy: DelayPreloadingStrategy,
        onSameUrlNavigation: 'reload',
        anchorScrolling: 'enabled'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
