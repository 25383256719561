import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { ConnectionServiceOptions } from './core/connection.service';
import { LogLevel } from './core/log/log-level';
import { Language } from './model/language';

export const lowAchievementLevel = 2;
export const wrongAnswerTrial = 3;
export const newAnswerTrial = 2;
export const serviceWorkerSriptFile = 'ngsw-worker.js';

export const xth1000WordsSyllabusIds = [
  '0ecec6c9-ced9-45df-a33e-a6cd26de828c',
  '2d618ef7-b52a-4e98-ed8a-7bab181fe9dd',
  'bb92b8fd-34ca-5d06-73fe-2f23df02c6ea',
  '47f8ca57-80c4-68df-d8ea-6fd57793787b',
  '850ab48f-3a3e-0b0f-4a8c-7f09be9fc7b0',
  '614ecd19-8f6d-44a8-b6a9-cf5c97dc56ee'
];

export const NoImageList = ['com'];

export const vocabularySyllabi = [
  {
    syllabusId: 'bc95481f-f299-48fe-a32a-994451dba1aa',
    language: Language.Chinese
},
  {
    syllabusId: '0b5b1930-2fd5-4a17-b673-3bc400467500',
    language: Language.English
  },
  {
    syllabusId: '721d96ac-fdf1-4415-a348-910650b738f7',
    language: Language.Spanish
  }
];

export const vocabularySyllabusIds : string[] = vocabularySyllabi.map(s => s.syllabusId);

export const englishVocabularySyllabusId : string = vocabularySyllabi.filter(s => s.language === Language.English)[0].syllabusId;

export const isDebuggingMessage = false;
export const isDebuggingNavigation = false;

export const legitimateLandingPreferenceTriggerHrefs = ['/','/home', '/vocabulary/menu', 'vocabulary/enter-word'];

@Injectable({ providedIn: 'root' })
export class Configuration {
  exerciseCounterStartValue = 1000000;
  vocabularyFreeLimit = 100;
  defaultVocabularyAcquisitionTarget = 5;
  webLoggerLogLevel = LogLevel.Error;
  cacheKey = 'SolidMemoryCacheKey';
  userPreferenceKey = 'userPreferenceKey';
  version = environment.version;
  effectDebounceTimeInMs = 250;
  effectThrottleTimeInMs = 250;
  exerciseUpdateThrottleTimeInMs = 1000;
  promiseRetryDelayInMs = 2000;

  exerciseSubmissionVerificationIntervalInMs = 5000;

  exerciseSubmissionRetryLimits = 5;

  showEmergencyMaintenance = false;

  newVocabularySoftLimit = 15;
  timeCalibrationThresholdInMin = 5;
  minimalMaskLength = 3;
  preloadBatchSize = 5;
  enableNotificationThreadhold = 50;
  questionOrderIncrement = 10;
  defaultQuestionOrder = 1010;
  syllabusLineLevelIncrement = 10;
  defaultSyllabusLineLevel = 10;
  imageQuality = 0.92;
  scanWidth = 3300;
  scanHeight = 2500;

  imageSize = 12;

  explanationToastrLifeInMs = 5 * 1000;

  couponCode = 'INITIAL_LAUNCH';

  carouselTransition = 5 * 1000;

  // storage Keys
  onBoardingOnceShownStorageKey = 'onBoardingOnceShown';
  uiCodeSelectedStorageKey = 'uiLanguageSelected';

  defaultSubject = 'English';
  enableNotificationPrompt = true;
  availableVocabularyLanguages = [
    Language.English,
    Language.Chinese,
    // Language.Spanish
  ];
  alternativeCharacterSupportedLanguages = [Language.Chinese];
  strokeSupportedLanguages = [Language.Chinese];

  supportEmailAddress = 'support@solidmemory.com';

  getExerciseDelayInMs = 3000;

  beepingCountdownForQuestionInMsInTest = 1000 * 20;
  beepingCountdownForAnswerInMsInTest = 1000 * 100;
  beepingCountdownForQuestionInMs = 1000 * 60;
  beepingCountdownForAnswerInMs = 1000 * 60 * 6;
  beepingPauseInMs = 1000;

  stsRootUrl: string = environment.stsRootUrl;
  apiRootUrl: string = environment.apiRootUrl;
  wsRootUrl: string = environment.wsRootUrl;
  originalApiRootUrl: string = environment.originalApiRootUrl;
  dictionaryRootUrl: string = environment.dictionaryRootUrl;
  erpRootUrl: string = environment.erpRootUrl;
  imageRootUrl: string = environment.imageRootUrl;
  audioRootUrl: string = environment.audioRootUrl;

  defaultReportCoverageInDays = 1;

  infoColor = '#5bc0de';
  solidColor = 'black';
  invertedSolidColor = 'white';
  errorColor = 'red';
  successColor = '#4CAF50';
  normalColor = '#607D8B';
  activeColor = '#4CAF50';
  highlightColor = '#FFFF88';
  linkColor = '#0000EE';

  katexHref = 'https://katex.org/docs/supported.html';
  visualMathEditorHref =
    'https://visualmatheditor.solidmemory.com/VisualMathEditor.html';

  defaultCustomScoreExpression = 'score / daysInAssessmentPeriod * 100';
  connectionServiceOptions: ConnectionServiceOptions = {
    enableHeartbeat: true,
    heartbeatUrl: '/'
  };

  dontShowThrottlingMessageKey = 'dontShowThrottlingMessageV8';

  guaranteeLevels = [
    [9, 30000],
    [5, 10000],
    [4, 8000],
    [3, 5000],
    [2, 4000],
    [1, 3000]
  ];

  userPreferenceSaveDeboundeTimeInMs = 2000;
  defaultMaxScoreInEachCustomAssessmentPeriod = 366;
  vocabularySetMaxSize = 50;
}
