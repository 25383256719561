import { MenuItem } from 'primeng/api';

export const schoolFaqMenu: MenuItem = {
  label: 'school-faq.school-faq',
  icon: 'fas fa-graduation-cap',
  items: [
    {
      label: 'school-faq.school-faq',
      routerLink: ['/school-faq/school-faqs']
    },
    {
      label: 'school-faq.the-only-tool-to-attain-fluent-english-without-a-language-environment',
      routerLink:['/school-faq/the-only-tool-to-attain-fluent-english-without-a-language-environment']
    },
    {
      label: 'slogan.the-only-learning-platform',
      routerLink: ['/school-faq/the-only-learning-platform-with-sufficient-statistical-data']
    },
    {
      label: 'school-faq.the-academic-rationale-of-solidmemory',
      routerLink: ['school-faq/the-academic-rationale-of-solidmemory']
    },
    {
      label: 'problem-set.personalize-number-of-new-questions',
      routerLink: ['school-faq/personalize-number-of-new-questions']
    },
    {
      label:
      'school-faq.modern-civilization-is-tool-civilization',
    routerLink: [
      '/school-faq/modern-civilization-is-tool-civilization'
    ]
    },
    {
      label:
        'problem-set.personalize-number-of-new-questions',
      routerLink: [
        '/school-faq/personalize-number-of-new-questions'
      ]
    },
    {
      label:
        'school-faq.reforming-the-school-curriculum-is-the-only-way-out',
      routerLink: [
        '/school-faq/reforming-the-school-curriculum-is-the-only-way-out'
      ]
    },
    {
      label:
        'school-faq.english-vocabulary-proficiency-test-system',
      routerLink: [
        '/school-faq/english-vocabulary-proficiency-test-system'
      ]
    },
    {
      label:
        'school-faq.a-revolution-of-education-industry-initiated-by-solidmemory-has-begun',
      routerLink: [
        '/school-faq/a-revolution-of-education-industry-initiated-by-solidmemory-has-begun'
      ]
    },
    {
      label: 'school-faq.teaching-materials-are-never-the-bottleneck',
      routerLink: ['/school-faq/teaching-materials-are-never-the-bottleneck']
    },
    {
      label:
        'school-faq.why-listening-speaking-reading-and-writing-cannot-solve-students-english-learning-problem',
      routerLink: [
        '/school-faq/why-listening-speaking-reading-and-writing-cannot-solve-students-english-learning-problem'
      ]
    },
    {
      label: 'faq.in-six-years',
      routerLink: [
        '/school-faq/in-six-years-everyone-could-attain-HKDSE-Level-5'
      ]
    },
    {
      label: 'faq.free-school-trial',
      routerLink: ['/school-faq/free-school-trial']
    },
    {
      label:
        'faq.why-are-the-overall-academic-performances-of-the-graduates-of-most-schools-always-stagnant',
      routerLink: ['/school-faq/why-are-the-results-always-stagnant']
    },
    {
      label: 'faq.why-is-homework-chasing-not-working',
      routerLink: ['/school-faq/why-is-homework-chasing-not-working']
    },
    {
      label:
        'faq.transparency-rather-than-HK1-2-million-a-year-is-what-a-school-needs',
      routerLink: [
        '/school-faq/transparency-rather-than-HK1-2-million-a-year-is-what-a-school-needs'
      ]
    },
    {
      label: 'faq.how-to-implement-in-school',
      routerLink: ['/school-faq/how-to-implement-in-school']
    },
    {
      label:
        'school-faq.how-to-validate-the-efficacy-of-the-english-vocabulary-learning-function-of-solidmemory-scientifically',
      routerLink: [
        '/school-faq/how-to-validate-the-efficacy-of-the-english-vocabulary-learning-function-of-solidmemory-scientifically'
      ]
    },
    {
      label: 'faq.the-only-effective-means-to-combat-wealth-disparity',
      routerLink: [
        '/school-faq/the-only-effective-means-to-combat-wealth-disparity'
      ]
    },
    {
      label: 'faq.how-to-enable-a-zero-mark-student-to-succeed',
      routerLink: ['/faq/how-to-enable-a-zero-mark-student-to-succeed']
    },
    {
      label: 'faq.how-effective',
      routerLink: ['/school-faq/how-effective']
    },
    {
      label: 'school-faq.how-to-create-huge-wealth-for-the-society',
      routerLink: ['/school-faq/how-to-create-huge-wealth-for-the-society']
    }
  ]
};
