import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from 'src/app/core/http-client.service';
import { Configuration } from '../../configuration';
import { CharacterStroke } from '../../model/character-stroke';
import { DictEntry } from '../../model/dict-entry';
import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { Phonetic } from '../../model/phonetic';
import { UICulture } from '../../model/ui-culture';

const dictEntriesPath = '/dictEntries';
const noCachePrefix = '/nocache';

@Injectable({ providedIn: 'root' })
export class DictionaryDataService {
  constructor(
    private http : HttpClientService,
    private config: Configuration
  ) {}

  getCharacterStroke(url: string): Observable<CharacterStroke> {
    return this.http.authGet<CharacterStroke>(url);
  }

  getAlternativeCharacters(url: string): Observable<string[]> {
    return this.http.authGet<string[]>(url);
  }

  getPhraseSuggestions(url: string): Observable<string[]> {
    return this.http.authGet<string[]>(url);
  }

  getPhonetics(url: string): Observable<Phonetic[]> {
    return this.http.authGet<Phonetic[]>(url);
  }

  getDictEntry(
    dictionaryLevel: Level,
    phrase: string,
    source: Language,
    target: Language,
    uiCulture: UICulture,
    newSearch: boolean,
    disableCache : boolean
  ): Observable<DictEntry> {
    const url =
      this.config.dictionaryRootUrl +
      `${disableCache?noCachePrefix:''}${dictEntriesPath}/${source}/${target}/${uiCulture}/${dictionaryLevel}?newSearch=${newSearch}&phrase=${encodeURIComponent(
        phrase
      )}`;

    return this.http.authGet<DictEntry>(url).pipe(
      map(this.mapToDictEntry),
      catchError((_) =>
        of(
          this.createObservableOfEmptyDictEntry(
            phrase,
            source,
            target,
            uiCulture
          )
        )
      )
    );
  }

  mapToDictEntry(data: DictEntry): DictEntry {
    const result: DictEntry = data;
    result.definitions = result.definitions
      .filter((d) => d.explanation)
      .map((d) => {
        if (d.partOfSpeech !== undefined) {
          d.pos = d.partOfSpeech;
        }
        return d;
      });
    return result;
  }

  createObservableOfEmptyDictEntry(
    lemma: string,
    source: Language,
    target: Language,
    uiCulture: UICulture
  ): DictEntry {
    return {
      antonyms: [],
      definitions: [],
      examples: [],
      homonyms: [],
      lemma,
      phonetics: [],
      source,
      synonyms: [],
      target,
      uiCulture
    };
  }
}
