export enum Pronunciation {
  BritishEnglish,
  AmericanEnglish,
  Putonghua,
  Cantonese,
  Japanese,
  Korean,
  AmericanSpanish,
  EuropeanSpanish,
  EuropeanFrench,
  CanadianFrench,
  German,
  Unspecified,
  EuropeanPortuguese,
  AmericanPortuguese,
  Afrikaans,
  Russian,
  Malay,
  Indonesian,
  Turkish,
  Arabic,
  Catalan,
  Italian,
  Polish,
  Urdu
}
