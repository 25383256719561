import { createSelector } from '@ngrx/store';
import { AppState } from '../../states/states-reducers';

const uiStateSelector = (state: AppState) => state.ui;

export const userAgentInfoSelector = createSelector(
  uiStateSelector,
  (state) => state.userAgentInfo
);

export const showTopBarSelector = createSelector(
  uiStateSelector,
  (state) => state.showTopBar
);

export const showSpinnerSelector = createSelector(
  uiStateSelector,
  (state) => state.showSpinner
);

export const showLocalStorageSelector = createSelector(
  uiStateSelector,
  (state) => state.showLocalStorageWarning
);
