import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import construe from 'cronstrue';
import { forkJoin, Observable } from 'rxjs';
import { exhaustMap, take } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';
import { SubSink } from 'subsink';
import { AppState } from '../../states/states-reducers';
import { languageCodeSelectedSelector } from '../user/user-preference-selectors';

const keys = [
  'purchase.vocabulary-membership-statement',
  'error.network-connected',
  'error.network-disconnected',
  'reporting.data-synchronized',
  'vocabulary.definitions-not-found',
  'dictionary.synonym',
  'vocabulary.priority-raised'
];

@Injectable({ providedIn: 'root' })
export class TranslationService {
  sub: SubSink = new SubSink();
  ianaCodeInUse$: Observable<string>;
  disconnectedMessage = '';
  dataSynchronizedMessage = '';
  connectedMessage = '';
  definitionNotFoundMessage = '';
  vocabFreeLimitStatement = '';
  synonym = '';
  priorityRaised = '';

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private config: Configuration
  ) {
    this.ianaCodeInUse$ = this.store.select(languageCodeSelectedSelector);

    this.sub.sink = this.translate.onLangChange
      .pipe(
        exhaustMap((_) =>
          forkJoin(keys.map((key) => this.translate.get(key)))
        )
      )
      .subscribe((values: any) => {
        this.vocabFreeLimitStatement = values[0].replace(
          'XXXX',
          this.config.vocabularyFreeLimit.toString()
        );
        this.connectedMessage = values[1];
        this.disconnectedMessage = values[2];
        this.dataSynchronizedMessage = values[3];
        this.definitionNotFoundMessage = values[4];
        this.synonym = values[5];
        this.priorityRaised = values[6];
      });
  }

  getTranslationInstant(key: string): string {
    return this.translate.instant(key);
  }

  getCronExpressionText(cronExpression: string): string {
    let ianaCode = this.translate.defaultLang;
    this.ianaCodeInUse$.pipe(take(1)).subscribe((code) => (ianaCode = code));
    const locale = this.getLocale(ianaCode);
    return construe.toString(cronExpression, { locale });
  }

  getLocale(languageCode: string): string {
    switch (languageCode) {
      case 'zh-Hant':
        return 'zh_TW';
      case 'zh-Hans':
        return 'zh_CN';
      default:
        return languageCode;
    }
  }
}
